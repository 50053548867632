import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import './ImageSlider.css';

const buttonStyle = {
  width: '30px',
  background: 'none',
  border: '0px',
};

const properties = {
  prevArrow: (
    <button style={{ ...buttonStyle }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff">
        <path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z" />
      </svg>
    </button>
  ),
  nextArrow: (
    <button style={{ ...buttonStyle }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff">
        <path d="M512 256L270 42.6v138.2H0v150.6h270v138z" />
      </svg>
    </button>
  ),
};

const Example = () => {
  return (
    <Slide {...properties}>
      <div className="each-slide-effect">
        <div className="editbg1">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 intro-text">
                  <div className="text-image">
                    <h1 className="h1-bg">Consultancy Concept</h1>
                    <p className="p-bg">
                      To give a best solution for our customer, we need no technology itself. We
                      need our customer as our partner to discuss their business objective and
                      environment thoroughly, together thus able to give the best result in
                      redefining IT Infrastructure for your Purpose
                    </p>
                    <a href="#about" className="btn btn-custom btn-lg page-scroll">
                      Learn More
                    </a>{' '}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="each-slide-effect">
        <div className="editbg2">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 intro-text">
                  <div className="text-image">
                    <h1 className="h1-bg">One Stop Solution</h1>
                    <p className="p-bg">
                      We deliver you a best-of-breed IT Infrastructure Technology that suits to your
                      business and budget. We are committed to provide our customer with timely and
                      efficient service.
                    </p>
                    <a href="#about" className="btn btn-custom btn-lg page-scroll">
                      Learn More
                    </a>{' '}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="each-slide-effect">
        <div className="editbg3">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 intro-text">
                  <div className="text-image">
                    <h1 className="h1-bg">We are a Landing Page</h1>
                    <p className="p-bg"></p>
                    <a href="#about" className="btn btn-custom btn-lg page-scroll">
                      Learn More
                    </a>{' '}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Slide>
  );
};

export default Example;
