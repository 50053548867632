import ImageSlider from './ImageSlider/ImageSlider';

export const Header = (props) => {
  return (
    <header id="home">
      <div className="">
        <ImageSlider />
      </div>
    </header>
  );
};
