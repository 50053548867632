export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="flex-container-top">
        {/* <div className="flex-item-left">
          <div className="icon-info">
            <img src={require('../../src/assets/envelope.png')} class="iconinfologo" alt="..." />
            <p className="font-p">sales@gmail.com</p>
          </div>
        </div>
        <div className="flex-item-mid">
          <div className="icon-info">
            <img src={require('../../src/assets/telephone.png')} class="iconinfologo" alt="..." />
            <p className="font-p">+621071071</p>
          </div>
        </div>
        <div className="flex-item-right">
          <div className="icon-info">
            <img src={require('../../src/assets/clock.png')} class="iconinfologo" alt="..." />
            <p className="font-p">08.00AM - 05.00 PM</p>
          </div>
        </div> */}
      </div>
      <hr className="tophr"></hr>
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {' '}
            <span className="sr-only">
              Toggle navigation
            </span> <span className="icon-bar"></span> <span className="icon-bar"></span>{' '}
            <span className="icon-bar"></span>{' '}
          </button>
          <a className="navbar-brand-edit" href="#home">
            <a href="#home">
              <img src={require('../../src/assets/mtu.png')} class="imglogo" alt="..." />
            </a>
            PT. Mahir Tekno Utama
          </a>{' '}
        </div>

        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#home" className="page-scroll">
                Home
              </a>
            </li>
            <li>
              <a href="#about" className="page-scroll">
                Tentang Kami
              </a>
            </li>
            <li>
              <a href="#services" className="page-scroll">
                Layanan
              </a>
            </li>
            <li>
              <a href="#pelanggan" className="page-scroll">
                Pelanggan Kami
              </a>
            </li>
            <li>
              <a href="#partner" className="page-scroll">
                Partner
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll">
                Kontak Kami
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
