export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Layanan</h2>
          <p></p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {' '}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3 className="layanan-h3">{d.name}</h3>
                    <hr className="layanan-hr" />
                    <li className="layanan-li">{d.text1}</li>
                    <li className="layanan-li">{d.text2}</li>
                    <li className="layanan-li">{d.text3}</li>
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  );
};
