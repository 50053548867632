export const Partner = () => {
  return (
    <div id="partner" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Partner</h2>
          <p></p>
        </div>
        <div className="partner-img">
          <div className="baru">
            <img src={require('../assets/partner/sangfor.png')} className="imglogo2" alt="..." />
          </div>
          <div className="baru">
            <img src={require('../assets/partner/Nutanix.png')} className="imglogo2" alt="..." />
          </div>
          <div className="baru">
            <img
              src={require('../assets/partner/allied-telesis.png')}
              className="imglogo2"
              alt="..."
            />
          </div>
          <div className="baru">
            <img src={require('../assets/partner/Fortinet.png')} className="imglogo2" alt="..." />
          </div>
          <div className="baru">
            <img src={require('../assets/partner/dell.png')} className="imglogo2" alt="..." />
          </div>
          <div className="baru">
            <img src={require('../assets/partner/ruijie.png')} className="imglogo2" alt="..." />
          </div>
          <div className="baru">
            <img src={require('../assets/partner/hp.png')} className="imglogo2" alt="..." />
          </div>
          <div className="baru">
            <img src={require('../assets/partner/Linux.png')} className="imglogo2" alt="..." />
          </div>
          <div className="baru">
            <img src={require('../assets/partner/ibm.png')} className="imglogo2" alt="..." />
          </div>
          <div className="baru">
            <img src={require('../assets/partner/cisco.png')} className="imglogo2" alt="..." />
          </div>
          <div className="baru">
            <img
              src={require('../assets/partner/dtc-netconnect.png')}
              className="imglogo2"
              alt="..."
            />
          </div>
          <div className="baru">
            <img src={require('../assets/partner/zimbra.png')} className="imglogo2" alt="..." />
          </div>
          <div className="baru">
            <img src={require('../assets/partner/kasp.png')} className="imglogo2" alt="..." />
          </div>
          <div className="baru">
            <img src={require('../assets/partner/hikvision.png')} className="imglogo2" alt="..." />
          </div>
          <div className="baru">
            <img src={require('../assets/partner/aruba.png')} className="imglogo2" alt="..." />
          </div>
        </div>
      </div>
    </div>
  );
};
