export const Pelanggans = (props) => {
  return (
    <div id="pelanggan" className=" text-center">
      <div className="container">
        <div className="section-title">
          <h2>Pelanggan Kami</h2>
          <p></p>
        </div>
        <div className="partner-img">
          <div className="baru">
            <img src={require('../assets/pelanggan/apl.png')} className="imglogo2" alt="..." />
          </div>
          <div className="baru">
            <img
              src={require('../assets/pelanggan/rsud-koja.png')}
              className="imglogo2"
              alt="..."
            />
          </div>
          <div className="baru">
            <img
              src={require('../assets/pelanggan/cj_cheiljedang.png')}
              className="imglogo2"
              alt="..."
            />
          </div>
          <div className="baru">
            <img
              src={require('../assets/pelanggan/rsud-duren-sawit.png')}
              className="imglogo2"
              alt="..."
            />
          </div>
          <div className="baru">
            <img
              src={require('../assets/pelanggan/cakrawala.png')}
              className="imglogo2"
              alt="..."
            />
          </div>
          <div className="baru">
            <img
              src={require('../assets/pelanggan/lintasarta.png')}
              className="imglogo2"
              alt="..."
            />
          </div>
          <div className="baru">
            <img src={require('../assets/pelanggan/cikarang.png')} className="imglogo2" alt="..." />
          </div>
          <div className="baru">
            <img
              src={require('../assets/pelanggan/true-money.png')}
              className="imglogo2"
              alt="..."
            />
          </div>
          <div className="baru">
            <img
              src={require('../assets/pelanggan/asia-pacific.png')}
              className="imglogo2"
              alt="..."
            />
          </div>
          <div className="baru">
            <img
              src={require('../assets/pelanggan/kementrian-lingkungan.png')}
              className="imglogo2"
              alt="..."
            />
          </div>
          <div className="baru">
            <img
              src={require('../assets/pelanggan/kementrian-sekretariatan.png')}
              className="imglogo2"
              alt="..."
            />
          </div>
          <div className="baru">
            <img src={require('../assets/pelanggan/bkkbn.png')} className="imglogo2" alt="..." />
          </div>
          <div className="baru">
            <img
              src={require('../assets/pelanggan/pt-reska-multi-usaha.png')}
              className="imglogo2"
              alt="..."
            />
          </div>
          <div className="baru">
            <img
              src={require('../assets/pelanggan/rsud-tanjung-priok.png')}
              className="imglogo2"
              alt="..."
            />
          </div>
          <div className="baru">
            <img src={require('../assets/pelanggan/bumitama.png')} className="imglogo2" alt="..." />
          </div>
          <div className="baru">
            <img
              src={require('../assets/pelanggan/kementrian-agama-diklat.png')}
              className="imglogo2"
              alt="..."
            />
          </div>
        </div>
      </div>
    </div>
  );
};
