import { useRef } from 'react';
import emailjs from '@emailjs/browser';

export const Contact = (props) => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_qvep0z9', 'template_t3e0rya', form.current, 'dwBw8uS_X2h1Y7M6k').then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
    e.target.reset();
  };

  // This means that your server does not accept your credentials. Some servers expect special configuration in order to allow clients using SMTP to prevent spam.

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Kontak Kami</h2>
                <p>
                  Please fill out the form below to send us an email and we will get back to you as
                  soon as possible.
                </p>
              </div>
              <form ref={form} onSubmit={sendEmail}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="user_name"
                        placeholder="Nama"
                        className="form-control"
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        name="user_email"
                        placeholder="Email"
                        className="form-control"
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea name="message" placeholder="Pesan" className="form-control"></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" value="Send" className="btn btn-custom btn-lg">
                  Kirim Pesan
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Info Kontak</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Alamat
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Telepon
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      {/* <i className="fa fa-facebook"></i> */}
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : '/'}>
                      {/* <i className="fa fa-twitter"></i> */}
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : '/'}>
                      {/* <i className="fa fa-youtube"></i> */}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2022{' '}
            <a href="http://www.mahirteknoutama.com" rel="nofollow">
              PT. Mahir Tekno Utama
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
